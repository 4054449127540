import { registerApplication, start, navigateToUrl } from 'single-spa';
import { constructApplications, constructRoutes, constructLayoutEngine } from 'single-spa-layout';
import { state$ } from '@fidubogota/store';
import microfrontendLayout from './microfrontend-layout.html';
import './styles/app.scss';

let authenticated = false;

const routes = constructRoutes(microfrontendLayout);

const applications = constructApplications({
    routes,
    loadApp({ name }) {
        return System.import(name);
    },
});

const layoutEngine = constructLayoutEngine({ routes, applications });

state$.subscribe(({ auth }) => {
    authenticated = !!auth.sessionToken;

    if (!authenticated && window.location.pathname !== '/') {
        navigateToUrl('/');
    } else if (authenticated && window.location.pathname === '/') {
        navigateToUrl('/home');
    }
});

applications.forEach(app => {
    app.activeWhen = location => {
        const isLoginApp = app.name === '@fidubogota/login';
        const isAuthenticatedApp = app.name === '@fidubogota/global-navbar';

        if (!authenticated) {
            return isLoginApp && ['/', '/'].includes(location.pathname);
        } else {
            return isAuthenticatedApp && ['/home', '/'].includes(location.pathname);
        }
    };

    registerApplication(app);
});

layoutEngine.activate();
start();
